import React from 'react';
import HeroSplit from '../components/sections/HeroSplit';
import HomeSplit from '../components/sections/HomeSplit';


class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <HeroSplit hasBgColor invertColor />
        <HomeSplit invertMobile imageFill topDivider />

      </React.Fragment>
    );
  }
}

export default Home;
