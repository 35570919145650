import React from 'react';
import classNames from 'classnames';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Button from '../elements/Button';



class ServicesCCC extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'How can we help you get to where you want to be?',
      paragraph: ''
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} tag="h1" className="center-content" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner has-shadow">

                  <div className="testimonial-item-header mb-16">
                    Online Classes
                    <div className="has-top-divider">
                    <br />

                    <div style={{display: 'flex',  justifyContent:'center'}}>
                    <Image
                      src={require('./../../assets/images/classes.jpg')}
                      alt="Classes"
                      width={250}
                      height={350} />
                    </div>

                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    We have developed guided sessions on a number of topics relating to blockchain technology. These are live online classes with a maximum class size of 16. With our expert tutors you will learn from root up in an interactive way, supplying you with the tools you need in order to succeed in the topic.
                    <br />
                    <br />

                    Classes available:
                    <ul>
                    <li>
                    Intro to Blockchain
                    </li>
                    <li>
                    Intro to Decentralised Finance
                    </li>
                    </ul>

                    These classes are coming soon at scheduled times.
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                    <Button color="primary" disabled>Coming Soon</Button>


                    </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                  Coaching

                    <div className="item-image has-top-divider">
                    <br />

                    <div style={{display: 'flex',  justifyContent:'center'}}>
                    <Image
                      src={require('./../../assets/images/coach.jpg')}
                      alt="Coach"
                      width={250}
                      height={350} />
                    </div>
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    There is no such thing as a stupid question when it comes to blockchain technology.
                    <br />
                    <br />
                    With our coaching service we can cover any aspect of blockchain technology that you want to get to grips with.
                    <br />
                    <br />

                    These will be one to one sessions, running at your pace aimed at getting you practically set up on blockchains and with cryptocurrency.
                    <br />
                    <br />


                    Contact us below so we can tailor a service that is right for you
                  </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                  <Button tag="a" color="primary" href="/contact">Contact</Button>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="400">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                  Consulting
                    <div className=" has-top-divider">
                    <br />
                    <div style={{display: 'flex',  justifyContent:'center'}}>
                    <Image
                      src={require('./../../assets/images/consult.jpg')}
                      alt="Consult"
                      width={250}
                      height={350} />
                    </div>
                    </div>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                    Does you business have a project that may have a blockchain use-case? Use our consulting service to take advantage of the expertise we have gained developing our own blockchain related projects in order to support you.

                    <br />
                    <br />
                    We can advise on your projects blockchain viability, architect the project blueprints and advise on its implementation, follow it through successful deployment and provide on going support.
                    <br />
                    <br />

                    Contact us below for further information
                    </p>
                  </div>
                  <div className="testimonial-item-footer text-xs fw-500 mt-32 mb-0 has-top-divider">
                  <Button tag="a" color="primary" href="/contact">Contact</Button>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ServicesCCC;
