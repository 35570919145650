import React from 'react';
// import section header
// import sections
import ProjectList from '../components/sections/ProjectList';


class Projects extends React.Component {

  render() {



    return (
      <React.Fragment>

      <ProjectList topDivider />

      </React.Fragment>
    );
  }
}

// inline style


export default Projects;
