import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SubscribePage from '../elements/subscribe';
import Modal from '../elements/Modal';
import Button from '../elements/Button';


const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}



class Cta extends React.Component {
  state = {
     showModal: false
   }

   openModal = (e) => {
     e.preventDefault();
     this.setState({ showModal: true });
   }

   closeModal = (e) => {
     e.preventDefault();
     this.setState({ showModal: false });
   }






  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'cta section center-content-mobile',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'cta-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
      split && 'cta-split'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div
            className={innerClasses}
          >
            <div className="cta-slogan">
              <h3 className="m-0">
                Join our newsletter

              </h3>
            </div>
            <div>


            <Button color="dark" onClick={this.openModal}>Join</Button>
                  <Modal
                    show={this.state.showModal}
                    handleClose={this.closeModal}
                  >
                  <h3 className="mt-0">
                  Join our newsletter

                  </h3>
                    <p class="m-0">

                  Keep up to date with all the latest Mulian and blockchain related news
                    <SubscribePage />
                  </p>


                 </Modal>







            </div>
          </div>
        </div>
      </section>
    );
  }
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;
