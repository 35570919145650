import React from 'react';
// import section header
// import sections


import ServicesCCC from '../components/sections/ServicesCCC';


class Services extends React.Component {

  render() {
    return (
      <React.Fragment>
      <ServicesCCC topDivider/>

      </React.Fragment>
    );
  }
}



export default Services;
