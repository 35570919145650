import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class ProjectList extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'team-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Our Projects',
paragraph: 'Here at Mulian we develop our own unique blockchain related projects. Sometimes serious sometimes fun, these projects are all about increasing awareness and engagement while reducing risks on the blockchain for the everyperson'    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="300">
              <div className="tiles-item-inner">
                <div className="team-item-header">
                  <div className="team-item-image mb-24">
                  <a href="https://www.love-does-not-compute.com">

                    <Image
                      src={require('./../../assets/images/LDNC-Logo-Top.png')}
                      alt="Love: Does Not Compute"
                      width={250}
                      height={250} />
                       </a>
                  </div>
                </div>
                <div className="team-item-content">
                  <h5 className="team-item-name mt-0 mb-4">

                  <a href="https://www.love-does-not-compute.com">Love: Does Not Compute</a>

                  </h5>
                  <p className="m-0 text-sm">
                  Merging the world of poetry and blockchains. We present to you the worlds first blockchain enabled poetry chapbook!
                  </p>
                </div>
              </div>
            </div>


              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">

                    <a href="https://www.eth-ter-i-die.rip">
                      <Image
                        src={require('./../../assets/images/EtID-logo.png')}
                        alt="Eth-ter-I-Die.rip"
                        width={180}
                        height={180}
                       />
                       </a>

                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                                <a href="https://www.eth-ter-i-die.rip">Eth-ter-I-Die</a>
                    </h5>

                    <p className="m-0 text-sm">
                    A proof of concept: Eth-ter-I-Die produces instructions for your non-technical beneficiary to convert your Ethereum Tokens, from a variety of different DApps, to Ether and transfer that Ether to your beneficiaries local currency.
                    </p>
                  </div>
                </div>
              </div>



              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="150">
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-24">
                      <Image
                        src={require('./../../assets/images/coming-soon.jpg')}
                        alt="Coming soon"
                        width={180}
                        height={180} />
                    </div>
                  </div>
                  <div className="team-item-content">
                    <h5 className="team-item-name mt-0 mb-4">
                    Coming Soon
                    </h5>
                    <p className="m-0 text-sm">
We're excited to be sharing this project with you soon enough. We've been working on this for a while!
                    </p>
                  </div>
                </div>
              </div>







            </div>
          </div>
        </div>
      </section>
    );
  }
}

ProjectList.propTypes = propTypes;
ProjectList.defaultProps = defaultProps;

export default ProjectList;
