import React from 'react'
import Input from './Input';

class SubscribePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        emailValue: '',
        fNameValue: '',
        lNameValue: '',
    };
  }

    render() {
        return (
                <form action="https://mulian.us6.list-manage.com/subscribe/post" method="POST" noValidate>
                <input type="hidden" name="u" value="da965f4e0e9ca1a116bef2bfb"/>
                <input type="hidden" name="id" value="527e22abd1"/>

                  <Input
                      label="Email"
                      placeholder="Enter Email"
                      type="email"
                      name="EMAIL"
                      required
                      id="MERGE0"
                      value={this.state.emailValue}
                      onChange={ (e)=>{this.setState({emailValue: e.target.value});} }
                      autoCapitalize="off"
                      autoCorrect="off"/>


                <Input
                  label="First Name"
                  placeholder="Enter First Name"
                  type="text"
                  required
                  name="FNAME"
                  id="MERGE1"
                  value={this.state.fNameValue}
                  onChange={(e)=>{this.setState({fNameValue: e.target.value});}}
                  />



                <Input
                  label="Last Name"
                  placeholder="Enter Last Name"
                  type="text"
                  required
                  name="LNAME"
                  id="MERGE2"
                  value={this.state.lNameValue}
                  onChange={(e)=>{this.setState({lNameValue: e.target.value});}}
                  />

                <br />
                  <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button"/>
              </form>
        )
    }
}

export default SubscribePage
