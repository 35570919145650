import React from 'react';
// import section header

import ContactForm2 from '../components/sections/ContactForm2';

// import some required elements

class Contact extends React.Component {

  render() {

    

    return (
      <React.Fragment>
      <ContactForm2 topDivider />




      </React.Fragment>
    );
  }
}



export default Contact;
