import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';
import { loadReCaptcha } from 'react-recaptcha-google';


// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutAlternative from './layouts/LayoutAlternative';
import LayoutSignin from './layouts/LayoutSignin';

// Views
import Home from './views/Home';
import Services from './views/Services';
import Projects from './views/Projects';
import About from './views/About';
import Classes from './views/Classes';
import Coach from './views/Coach';
import Consult from './views/Consult';
import All from './views/All';
import Contact from  './views/Contact';


import Secondary from './views/Secondary';
import Login from './views/Login';
import Signup from './views/Signup';






class App extends React.Component {


  componentDidMount() {
    loadReCaptcha();
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/services" component={Services} layout={LayoutAlternative} />
            <AppRoute exact path="/projects" component={Projects} layout={LayoutAlternative} />
            <AppRoute exact path="/about" component={About} layout={LayoutAlternative} />
            <AppRoute exact path="/classes" component={Classes} layout={LayoutAlternative} />
            <AppRoute exact path="/coach" component={Coach} layout={LayoutAlternative} />
            <AppRoute exact path="/consult" component={Consult} layout={LayoutAlternative} />
            <AppRoute exact path="/all" component={All} layout={LayoutAlternative} />
            <AppRoute exact path="/contact" component={Contact} layout={LayoutAlternative} />


            <AppRoute exact path="/secondary" component={Secondary} layout={LayoutAlternative} />
            <AppRoute exact path="/login" component={Login} layout={LayoutSignin} />
            <AppRoute exact path="/signup" component={Signup} layout={LayoutSignin} />
          </Switch>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);
