// Contact.js
import React, { useState } from 'react';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Select from  '../elements/Select';


import { useForm } from 'react-hook-form';
import { init, sendForm } from 'emailjs-com';
init('user_BW3kP758RzT0uaqs3NAdC');




const Contact = () => {

const { register, handleSubmit, watch, formState: { errors } } = useForm();
const onSubmit = (data) => {
  // console.log(data);
  const statusMessage = document.querySelector('.status-message');

  const form = document.querySelector('#contact-form');
  generateContactNumber();
  sendForm('default_service', 'template_b84c5bl', '#contact-form')
    .then(function(response) {
      console.log('SUCCESS!', response.status, response.text);
      form.reset();
      setStatusMessage("Message sent!");
      statusMessage.className = "status-message success";
      setTimeout(()=> {
        statusMessage.className = 'status-message'
      }, 5000)
    }, function(error) {
      console.log('FAILED...', error);
      setStatusMessage("Failed to send message! Please try again later.");
      statusMessage.className = "status-message failure";
      setTimeout(()=> {
        statusMessage.className = 'status-message'
      }, 5000)
    });
}


const message = watch('message') || "";
const messageCharsLeft = 1500 - message.length;


  const [contactNumber, setContactNumber] = useState("000000");

  const generateContactNumber = () => {
    const numStr = "000000" + (Math.random() * 1000000 | 0);
    setContactNumber(numStr.substring(numStr.length - 6));

  }
  const [statusMessage, setStatusMessage] = useState("");



  return (

    <div className='contact'>

      <p className='status-message'>{statusMessage}</p>

      <form id='contact-form' onSubmit={handleSubmit(onSubmit)}>

      {errors.user_name && errors.user_name.type === "required" && (
        <div role="alert">Name is required<br/></div>
      )}


    <Input
    type='text'
    name='user_name'
    placeholder='Name'
    aria-invalid={errors.user_name ? "true" : "false"}
    {...register('user_name', { required: "required" })}

     />


    <br/>

    {errors.user_email && errors.user_email.type === "required" && (
      <div role="alert">Email is required<br/></div>   )}


    <Input
    type='email'
    name='user_email'
    placeholder='Email'
    aria-invalid={errors.user_email ? "true" : "false"}
    {...register('user_email', { required: "required" })} />

    <br/>

    {errors.user_topic && errors.user_topic.type === "required" && (
      <div role="alert">A topic is required<br/></div>   )}


    <Select
         placeholder= "Select Topic"
         name='user_topic'
         aria-invalid={errors.user_topic ? "true" : "false"}
         {...register('user_topic', { required: "required" })}
            >

          <option value="Coaching">Coaching</option>
           <option value="Consulting">Consulting</option>
           <option value="Other">Other</option>
       </Select>




    <br/>

    {errors.message && errors.message.type === "required" && (
      <div role="alert">Message content is required<br/></div>   )}
    <Input
    type='textarea'
    name='message'
    placeholder='Message'
    maxLength='1500'
    aria-invalid={errors.message ? "true" : "false"}
    {...register('message', { required: "required" })}
    />
    <p className='message-chars-left'>{messageCharsLeft} characters left</p>
    <br/>


<input type='hidden' name='contact_number' value={contactNumber} />
    <br/>

    <Button type='submit' value='Send' color="primary"  wideMobile>
    Submit
    </Button>
    <br/>
    <br/>

  </form>
  </div>


  );
}
export default Contact;
