import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections

import GenericSection from '../components/sections/GenericSection';
// import some required elements
import Image from '../components/elements/Image';
import { Link } from 'react-router-dom';


class About extends React.Component {

  render() {

    const genericSection04Header = {
      title:'Consulting Expertise'
    }

    return (
      <React.Fragment>

        <GenericSection topDivider>
          <div className="container-xs">
            <h2 className="mt-0">Mulian is a blockchain projects and consultation studio with a focus on education</h2>
            <p>


            Does you business have a project that may have a blockchain use-case? This is where Mulian helps. Mulian can advise on the projects blockchain viability, architect the projects blueprints and advise on its implementation and successful deployment.
            </p>
            <figure>
              <Image
                className="image-larger"
                src={require('./../assets/images/network.jpg')}
                alt="Networks"
                width={350}
                height={200} />
            </figure>
            <p>
            We utilise the expertise we have gained developing our own blockchain related projects in order to support you. Our own projects are mainly focussed improving the world of blockchain so that the everyperson can benefit.
            </p>

            <p>
            The team at Mulian believes that everybody should be allowed to take advantage of blockchain technologies. To enable that, we develop our own blockchain projects and education/ coaching materials in order to enable people to become blockchain ready.
            </p>

            <p>

            We develop classes and offer coaching on:

            <ul>
              <li>Introduction to Blockchains</li>
              <li>Decentralised Finance*</li>
              <li>Staking and Validation</li>
              <li>Developing on the Blockchain</li>
            </ul>

            * Mulian will never make financial advice and is very much focussed on explaining the risks and reducing them.

            </p>

          </div>
            </GenericSection>




            <GenericSection topDivider>
              <div className="container-xs">
                <SectionHeader data={genericSection04Header} className="center-content" />
                <p>
                The Mulian team has an abundance of experience in numerous subject areas that can assist your projects.
                <ul>
                <li>Blockchain Technology</li>
                <li>Application Development</li>
                <li>Machine Learning</li>
                <li>Cloud Solutions</li>
                <li>Industrial Internet of things (Industry 4.0)</li>

                </ul>
                If you have any questions about how we can assist your project please  <Link to="/contact/">get in touch!</Link>

                  </p>
                      </div>
            </GenericSection>




      </React.Fragment>
    );
  }
}



export default About;
